import React, { createElement } from 'react';
import { Spin } from 'antd';
import pathToRegexp from 'path-to-regexp';
import Loadable from 'react-loadable';
import { getUserPermission } from 'components/PermissionCheck/PermissionCheck';
import { getUserRole } from 'src/utils/tenantContext';
import { PredefinedRole } from 'src/services/types/cedar/role';
import { getMenuData } from './menu';

let routerDataCache;

const modelNotExisted = (app, model) =>
  // eslint-disable-next-line
  !app._models.some(({ namespace }) => {
    return namespace === model.substring(model.lastIndexOf('/') + 1);
  });

// wrapper of dynamic
const dynamicWrapper = (app, models, component) => {
  // register models
  models.forEach((model) => {
    if (modelNotExisted(app, model)) {
      // eslint-disable-next-line
      app.model(require(`../models/${model}`).default);
    }
  });

  // transformed by babel-plugin-dynamic-import-node-sync
  if (component.toString().indexOf('.then(') < 0) {
    return (props) => {
      if (!routerDataCache) {
        routerDataCache = getRouterData(app);
      }
      return createElement(component().default, {
        ...props,
        routerData: routerDataCache,
      });
    };
  }

  return Loadable({
    loader: () => {
      if (!routerDataCache) {
        routerDataCache = getRouterData(app);
      }
      if (!navigator.onLine) {
        window.location.reload(false);
        return null;
      }
      return component().then((raw) => {
        const Component = raw.default || raw;
        return (props) =>
          createElement(Component, {
            ...props,
            routerData: routerDataCache,
          });
      });
    },
    loading: () => {
      return <Spin size="large" className="global-spin" />;
    },
  });
};

function getFlatMenuData(menus) {
  let keys = {};
  menus.forEach((item) => {
    if (item.children) {
      keys[item.path] = { ...item };
      keys = { ...keys, ...getFlatMenuData(item.children) };
    } else {
      keys[item.path] = { ...item };
    }
  });
  return keys;
}

export const getRouterData = (app) => {
  const routerConfig = {};

  const isTenantAdmin = (name) => {
    const tenantAdminEffectList = [
      '用户消息配置',
    ];

    return (getUserRole() === PredefinedRole.SUPER_ADMINISTRATOR
      || getUserRole() === PredefinedRole.TENANT_ADMINISTRATOR)
      && tenantAdminEffectList.includes(name);
  }

  const addPermissionRoute = (path, models, comp, permissionCode, name) => {
    if (!permissionCode || getUserPermission()[permissionCode] || isTenantAdmin(name))
      routerConfig[path] = {
        name: name && i(name),
        component: dynamicWrapper(app, models, comp),
        platform: 'app',
      };
  };

  addPermissionRoute(
    '/robot/manager',
    ['robot', 'login'],
    () => import('../routes/Robot/List/ListWrapper'),
    'Robot.Manager',
  );
  //2023-12-13 功能下架暂不删除，删除时间待定
  // addPermissionRoute(
  //   '/robot/factory',
  //   ['robot', 'login'],
  //   () => import('../routes/Robot/Factory/factory'),
  //   'Robot.Factory',
  //   '机器人注册',
  // );

  addPermissionRoute(
    '/dataReflux/dataRefluxPlan',
    ['robot', 'login'],
    () => import('../routes/Robot/DataReflux/DataReflux'),
    'DataReflux.DataRefluxPlan',
    "数据回流计划"
  );

  addPermissionRoute(
    '/robot/additional-info',
    ['robot', 'login'],
    () => import('../routes/Robot/AdditionalInfo/AdditionalInfo'),
    'Robot.RegisterAddition',
    '机器人注册-补充信息',
  );

  addPermissionRoute(
    '/robot/upper-computer-backup-detail/:sn?',
    ['robot', 'login'],
    () => import('../routes/Robot/UpperComputerBackup/Detail/Detail'),
    'Robot.UpperComputerBackup',
    '上位机备份详情',
  );


  addPermissionRoute(
    '/robot/upper-computer-backup',
    ['robot', 'login'],
    () => import('../routes/Robot/UpperComputerBackup/UpperComputerBackup'),
    'Robot.UpperComputerBackup',
    '上位机备份',
  );


  addPermissionRoute(
    '/robot/oneclick-switch',
    ['robot', 'login'],
    () => import('../routes/Robot/OneclickSwitch/OneClickSwitch'),
    'Robot.MigrationPlan',
    '一键换机',
  );

  addPermissionRoute(
    '/robot/oneclick-switch-plan/planlist',
    ['robot', 'login'],
    () => import('../routes/Robot/OneclickSwitch/PlanList'),
    'Robot.MigrationPlan',
    '一键换机',
  );

  addPermissionRoute(
    '/robotDeploy/laserActive',
    [],
    () => import('../routes/RobotDeploy/LaserActive'),
    'Deploy.LaserActivation',
    '激光激活',
  );

  addPermissionRoute(
    '/robot/detail/:id/:curModule?/:name?',
    ['robot', 'login'],
    () => import('../routes/Robot/Detail/Detail'),
    'Robot.Manager.Detail',
  );

  addPermissionRoute(
    '/robot/old/detail/:id/:curModule?/:name?',
    ['robot', 'login'],
    () => import('../routes/Robot/Detail/OldVersionTheRobotDetail/Detail'),
    'Robot.Manager.Detail',
  );

  addPermissionRoute(
    '/robot/task-report/:id',
    ['robot', 'download'],
    () => import('../routes/Robot/TaskReport/TaskReport'),
    'Robot.Manager.Detail.Task',
  );

  addPermissionRoute(
    '/robot/real-time/:id',
    ['robot', 'login'],
    () => import('../routes/Robot/Realtime/Realtime'),
    'Robot.Manager.Detail.RealTimeViewButtonShow',
    '实时视图',
  );

  addPermissionRoute(
    '/robot/old/real-time/:id',
    ['robot', 'login'],
    () => import('../routes/Robot/Detail/OldVersionTheRobotDetail/RealTime/Realtime'),
    'Robot.Manager.Detail.RealTimeViewButtonShow',
    '实时视图',
  );

  addPermissionRoute(
    '/robot/robot-status/laser/:id',
    ['robot', 'login'],
    () => import('../routes/Robot/RobotStatus/RobotStatusLaser'),
    'Robot.Manager.Detail.SensorDataButtonShow',
    '传感数据',
  );

  addPermissionRoute(
    '/robot/old/robot-status/laser/:id',
    ['robot', 'login'],
    () => import('../routes/Robot/Detail/OldVersionTheRobotDetail/RobotStatus/RobotStatusLaser'),
    'Robot.Manager.Detail.SensorDataButtonShow',
    '传感数据',
  );

  addPermissionRoute(
    '/robot/account-relation/:id',
    ['robot'],
    () => import('../routes/Robot/RelatedAccounts/RelatedAccount'),
    'Robot.Manager.LinkedAccountButtonShow',
    '相关账户',
  );

  addPermissionRoute(
    '/robot/task',
    ['robot', 'download'],
    () => import('../routes/TaskManager/TaskManager'),
    'Robot.Task',
  );


  addPermissionRoute(
    '/event/definition',
    ['robot', 'login'],
    () => import('../routes/MicroApp/MicroApp'),
    'EventCenter.Definition',
    '事件定义',
  );

  addPermissionRoute(
    '/event/multi-language',
    ['robot', 'login'],
    () => import('../routes/MicroApp/MicroApp'),
    'EventCenter.MultilingualManager',
    '事件多语言管理',
  );

  addPermissionRoute(
    '/event/field-config/:configType',
    ['robot', 'login'],
    () => import('../routes/MicroApp/MicroApp'),
    'EventCenter',
    '字段配置',
  );

  addPermissionRoute(
    '/robot/shared-record-history',
    ['robot', 'login'],
    () => import('../routes/DataCenter/SharedRecord/SharedRecordHistory/SharedRecordHistory'),
    'Robot',
    '记录分享历史',
  );

  addPermissionRoute(
    '/robot/remote-control-log',
    ['robot', 'login'],
    () => import('../routes/Robot/RemoteControlLog/RemoteControlLog'),
    'Robot.RemoteControl',
    '远程控制日志',
  );

  addPermissionRoute(
    '/robot/shared-record-list/:id/:sn?',
    ['robot', 'login'],
    () => import('../routes/DataCenter/SharedRecord/SharedRecordList/SharedRecordList'),
    'Robot',
    '记录列表',
  );

  addPermissionRoute(
    '/event/send-template',
    ['robot', 'login'],
    () => import('../routes/MicroApp/MicroApp'),
    'EventCenter.SendTemplateConfig',
    '通知模板配置'
  );

  addPermissionRoute(
    '/event/tenant-message-config',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'EventCenter.TenantMessageConfig',
    '租户消息配置',
  );

  addPermissionRoute(
    '/event/user-message-config',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'EventCenter.UserMessageConfig',
    '用户消息配置',
  );

  addPermissionRoute(
    '/event/template-management/:pageType/:operationType',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'EventCenter',
    '模板管理',
  );

  addPermissionRoute(
    '/event/template-content/:pageType/:operationType/:id?',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'EventCenter',
  );

  addPermissionRoute(
    '/event/robot-config/:operationType/:id?',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'EventCenter.EventSubscribe',
    '机器人配置表',
  );

  addPermissionRoute(
    '/remote-maintenance/remoteOperator-workbench/:sn?/:eventTicketId?',
    ['robot', 'login'],
    () => import('../routes/MicroApp/MicroApp'),
    'RemoteMaintenance.RemoteOperator.List',
    '远程运维工作台',
  );

  addPermissionRoute(
    '/remote-maintenance/group-set',
    ['robot', 'login'],
    () => import('../routes/MicroApp/MicroApp'),
    'RemoteMaintenance.Group.Set',
    '分组设置',
  );

  addPermissionRoute(
    '/remote-maintenance/workbench-config',
    ['robot', 'login'],
    () => import('../routes/MicroApp/MicroApp'),
    'RemoteMaintenance.Workbench.Config',
    '工作台配置',
  );

  addPermissionRoute(
    '/remote-maintenance/person-group-set',
    ['robot', 'login'],
    () => import('../routes/MicroApp/MicroApp'),
    'RemoteMaintenance.Person.Group.Set',
    '人员分组设置',
  );

  addPermissionRoute(
    '/remote-maintenance/person-group-set/create',
    ['robot', 'login'],
    () => import('../routes/MicroApp/MicroApp'),
    'RemoteMaintenance.Person.Group.Set',
    '人员分组设置-新增',
  );

  addPermissionRoute(
    '/remote-maintenance/person-group-set/edit/:id',
    ['robot', 'login'],
    () => import('../routes/MicroApp/MicroApp'),
    'RemoteMaintenance.Person.Group.Set',
    '人员分组设置-编辑',
  );

  addPermissionRoute(
    '/remote-maintenance/person-group-set/view/:id',
    ['robot', 'login'],
    () => import('../routes/MicroApp/MicroApp'),
    'RemoteMaintenance.Person.Group.Set',
    '人员分组设置-查看',
  );

  addPermissionRoute(
    '/remote-maintenance/person-group-set/addOrDeletePerson',
    ['robot', 'login'],
    () => import('../routes/MicroApp/MicroApp'),
    'RemoteMaintenance.Person.Group.Set',
    '人员分组设置-添加执行人员',
  );

  addPermissionRoute(
    '/remote-maintenance/dispatch-event-ticket-manager',
    ['robot', 'login'],
    () => import('../routes/MicroApp/MicroApp'),
    'RemoteMaintenance.DispatchEventTicketManager',
    '分单管理',
  );

  addPermissionRoute(
    '/remote-maintenance/dispatch-event-ticket-manager/create',
    ['robot', 'login'],
    () => import('../routes/MicroApp/MicroApp'),
    'RemoteMaintenance.DispatchEventTicketManager',
    '分单管理-新增',
  );

  addPermissionRoute(
    '/remote-maintenance/dispatch-event-ticket-manager/edit/:id',
    ['robot', 'login'],
    () => import('../routes/MicroApp/MicroApp'),
    'RemoteMaintenance.DispatchEventTicketManager',
    '分单管理-编辑',
  );

  addPermissionRoute(
    '/remote-maintenance/dispatch-event-ticket-manager/view/:id',
    ['robot', 'login'],
    () => import('../routes/MicroApp/MicroApp'),
    'RemoteMaintenance.DispatchEventTicketManager',
    '分单管理-查看',
  );

  addPermissionRoute(
    '/event/event-analysis/:sn?',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'EventCenter.Analysis',
    '事件分析',
  );

  addPermissionRoute(
    '/event/new-event-analysis',
    [],
    () => import("../routes/MicroApp/MicroApp"),
    'EventCenter.AnalysisExternal',
    '事件分析',
  );

  // addPermissionRoute(
  //   '/event/event-manager',
  //   ['robot', 'login'],
  //   () => import("../routes/MicroApp/MicroApp"),
  //   'EventCenter.Manager',
  //   '事件管理',
  // );

  addPermissionRoute(
    '/event/jira-record',
    ['robot', 'login'],
    () => import('../routes/MicroApp/MicroApp'),
    'EventCenter.JiraRecord',
    'Jira记录',
  );

  addPermissionRoute(
    '/customerService/repair-management',
    ['robot', 'login', "customerService"],
    () => import('../routes/CustomerService/RepairManager/RepairManager'),
    'CustomerAssist.RepairManager.List',
    '报修管理',
  );

  addPermissionRoute(
    '/customerService/customer-information',
    ['robot', 'login', "customerService"],
    () => import('../routes/CustomerService/CustomerInformation/CustomerInformation'),
    'CustomerAssist.CustomerInformation.List',
    '客户信息',
  );

  addPermissionRoute(
    '/customerService/customer-information-detail/:type/:id?',
    ['robot', 'login', "customerService"],
    () => import('../routes/CustomerService/CustomerInformation/Detail/CustomerInformationDetail'),
    'CustomerAssist.CustomerInformation.List',
    '客户信息/详情',
  );

  addPermissionRoute(
    '/map/list',
    ['robot', 'login'],
    () => import('../routes/MapManager/MapList/MapList'),
    'MapManager.List.Others',
    '地图列表',
  );

  addPermissionRoute(
    '/map/mapEdit/:mapId/:mapVersion/:readonly?',
    ['robot', 'login'],
    () => import('../routes/MicroApp/MicroApp'),
    'MapManager.List.EditVersion',
    '地图编辑'
  );

  addPermissionRoute(
    '/map/detail/:id',
    ['robot', 'login'],
    () => import('../routes/MapManager/MapDetail/MapDetail'),
    'MapManager.List.Others',
    '地图详情',
  );

  addPermissionRoute(
    '/map/issued-record',
    ['robot', 'login'],
    () => import('../routes/MapManager/MapIssuedRecord/MapIssuedRecord'),
    'MapManager.Transmission.List',
    '下发记录',
  );

  addPermissionRoute(
    '/map/edit-record',
    ['robot', 'login'],
    () => import('../routes/MapManager/MapEditRecord'),
    'MapManager.EditRecord',
    '编辑记录',
  );

  addPermissionRoute(
    '/system/product',
    ['product'],
    () => import('../routes/Admin/Product/Product'),
    'System.Product',
    '产品管理',
  );
  addPermissionRoute(
    '/system/firmware',
    [],
    () => import('../routes/Firmware/Firmware/Firmware'),
    'System.Firmware',
    '固件管理',
  );
  addPermissionRoute(
    '/system/firmware-push/:robotFamily/:id',
    [],
    () => import('../routes/Firmware/Firmware/FirmwarePush'),
    'System.Firmware',//todo
    '固件推送',
  );
  addPermissionRoute(
    '/system/firmware-history/:name/:firmwareVersionName/:version/:robotFamily',
    [],
    () => import('../routes/Firmware/Firmware/FirmwareHistory'),
    'System.Firmware',//todo
    '固件推送历史',
  );

  addPermissionRoute(
    '/system/account',
    ['map', 'account'],
    () => import('../routes/Admin/Member/Account/List'),
    'System.Account',
    '账号管理',
  );

  addPermissionRoute(
    '/system/account-role/:role',
    ['map', 'account'],
    () => import('../routes/Admin/Member/Account/List'),
    'System.Account',//todo
    '账号管理',
  );

  addPermissionRoute(
    '/system/account-detail',
    ['map', 'account'],
    () => import('../routes/Admin/Member/Account/CreateAccount'),
    'System.Account',//todo
    '账号管理',
  );

  addPermissionRoute(
    '/system/permission',
    [],
    () => import('../routes/Admin/Permission/RoleList'),
    'System.Permission',
    '权限管理',
  );
  addPermissionRoute(
    '/system/permission-add',
    [],
    () => import('../routes/Admin/Permission/PermissionTree'),
    'System.Permission',//todo
    '新增权限',
  );
  addPermissionRoute(
    '/system/permission-modify/:roleId',
    [],
    () => import('../routes/Admin/Permission/PermissionTree'),
    'System.Permission',//todo
    '修改权限',
  );
  addPermissionRoute(
    '/system/robotParamsPermission',
    [],
    () => import('../routes/Admin/RobotParamsPermission/List'),
    'System.ScriptsUpload',//todo
    '智能运维',
  );
  addPermissionRoute(
    '/upgrade/firmware-manager/:name?',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Upgrade.FirmwareManager',
    '升级管理/版本管理'
  );
  addPermissionRoute(
    '/upgrade/firmware-manager/detail/:name?',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Upgrade.FirmwareManager',//todo
    '升级管理/版本管理/固件详情'
  );
  addPermissionRoute(
    '/upgrade/firmware-history/:title/:deviceScope/bundles/:name/:state',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Upgrade.FirmwareManager',//todo
    '升级管理/版本管理/升级历史'
  );
  addPermissionRoute(
    '/upgrade/robot-upgrade/:sn?',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Upgrade.RobotUpgrade.List',
    '机器人升级'
  );
  addPermissionRoute(
    '/upgrade/firmware-push-plan',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Upgrade.FirmwarePushPlan',
    '版本推送计划'
  );
  addPermissionRoute(
    '/upgrade/upgrade-history/:sn?/:upgradeTrigger?',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Upgrade.History',
    '升级管理/升级历史'
  );
  addPermissionRoute(
    '/upgrade/upgrade-log',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Upgrade.Log',
    '升级管理/升级事件日志'
  );

  addPermissionRoute(
    '/upgrade-service/robot-upgrade-service',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'ExternalUpgradeService.RobotUpgrade',
    '升级服务/机器人升级'
  );

  addPermissionRoute(
    '/upgrade-service/push-manager-service',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'ExternalUpgradeService.FirmwarePushPlan',
    '升级服务/版本推送计划'
  );

  addPermissionRoute(
    '/argument/upgrade-management',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Argument.UpgradeManagement',
    '参数管理/参数升级管理'
  );

  addPermissionRoute(
    '/argument/issue-history',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Argument.IssueHistory',
    '参数管理/参数升级历史'
  );

  addPermissionRoute(
    '/site/list',
    ['robot', 'login'],
    // () => import('../routes/SiteManager/SiteList/SiteList'),
    () => import("../routes/MicroApp/MicroApp"),
    'SiteManager.List',
    '站点管理/站点列表'
  );
  addPermissionRoute(
    '/site/detail/:id/:operate?',
    ['robot', 'login'],
    // () => import('../routes/SiteManager/SiteList/SiteDetail/SiteDetail'),
    () => import("../routes/MicroApp/MicroApp"),
    'SiteManager.List',
    '站点列表/站点详情'
  );

  addPermissionRoute(
    '/site/add',
    ['robot', 'login'],
    // () => import('../routes/SiteManager/SiteDownHistory/SiteDownHistory'),
    () => import("../routes/MicroApp/MicroApp"),
    'SiteManager.List',
    '站点列表/站点详情'
  );

  addPermissionRoute(
    '/site/down',
    ['robot', 'login'],
    // () => import('../routes/SiteManager/SiteDownHistory/SiteDownHistory'),
    () => import("../routes/MicroApp/MicroApp"),
    'SiteManager.DownHistory',
    '站点列表/站点下发记录'
  );

  addPermissionRoute(
    '/elevator/management',
    ['robot', 'login'],
    // () => import('../routes/Elevator/ElevatorManagement/ElevatorManagement'),
    () => import("../routes/MicroApp/MicroApp"),
    'ElevatorManager.List',
    '电梯数据',
  );

  addPermissionRoute(
    '/elevator/control',
    ['robot', 'login'],
    // () => import('../routes/Elevator/ElevatorControl/ElevatorControl'),
    () => import("../routes/MicroApp/MicroApp"),
    'ElevatorManager.Solution.List',
    '梯控方案'
  );

  addPermissionRoute(
    '/elevator/solution/:id',
    ['robot', 'login'],
    // () => import('../routes/Elevator/ElevatorControl/ElevatorSolution'),
    () => import("../routes/MicroApp/MicroApp"),
    'ElevatorManager.Solution.List',
  );

  addPermissionRoute(
    '/elevator/detail/:elevatorId',
    ['robot', 'login'],
    // () => import('../routes/Elevator/ElevatorManagement/ElevatorDetail'),
    () => import("../routes/MicroApp/MicroApp"),
    'ElevatorManager.List',
    '电梯详情',
  );
  addPermissionRoute(
    '/elevator/ota',
    ['robot', 'login'],
    // () => import('../routes/Elevator/ElevatorOTA/ElevatorOTA'),
    () => import("../routes/MicroApp/MicroApp"),
    'ElevatorManager.BundleList',
    '梯控OTA',
  );
  addPermissionRoute(
    '/elevator/controlBoard/:ecbSn?',
    ['robot', 'login'],
    // () => import('../routes/Elevator/ElevatorOTA/ElevatorOTA'),
    () => import("../routes/MicroApp/MicroApp"),
    'ElevatorManager.EcbManagement',
    '梯控板管理',
  );

  addPermissionRoute(
    '/elevator/manager/:ecMode?',
    [],
    () => import("../routes/MicroApp/MicroApp"),
    'ElevatorManager.ElevatorManagement',
    '电梯管理',
  );

  addPermissionRoute(
    '/elevator/runtimeRecord',
    [],
    () => import("../routes/MicroApp/MicroApp"),
    'ElevatorManager.ElevatorTaskRecord',
    '电梯运行记录',
  );

  addPermissionRoute(
    '/elevator/cloudElevator',
    [],
    () => import("../routes/MicroApp/MicroApp"),
    'ElevatorManager.CloudElevatorManufacturerManagement',
    '云电梯厂商管理',
  );

  addPermissionRoute(
    '/robot-info/tool-app/',
    ['robot', 'login'],
    () => import('../routes/RobotInfoManager/ToolAppReportInfoManager/ToolAppReportInfoManager'),
    'RobotInfo.ToolApp',
    '工具APP上报信息管理',
  );

  addPermissionRoute(
    '/robot-info/detail/:modelType/:robotNo',
    ['robot', 'login'],
    () => import('../routes/RobotInfoManager/ToolAppReportInfoManager/Detail'),
    'RobotInfo.ToolApp',
    '详情',
  );

  addPermissionRoute(
    '/udesk-center/udesk-manager',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Udesk.Manager',
    '工单管理',
  );

  addPermissionRoute(
    '/udesk-center/udesk-manager/view/:id',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Udesk.Manager',
    '工单管理-工单详情',
  );

  addPermissionRoute(
    '/udesk-center/generalConfig',
    [],
    () => import("../routes/MicroApp/MicroApp"),
    'Udesk.GeneralConfig',
    '工单管理-通用配置',
  );
  //2023-12-13 功能下架暂不删除，删除时间待定---
  // addPermissionRoute(
  //   '/udesk-center/udeskType-template',
  //   ['robot', 'login'],
  //   () => import("../routes/MicroApp/MicroApp"),
  //   'Udesk.UdeskTypeTemplate',
  //   '工单类型模版',
  // );

  // addPermissionRoute(
  //   '/udesk-center/contentTemplateList',
  //   ['robot', 'login'],
  //   () => import("../routes/MicroApp/MicroApp"),
  //   'Udesk.ContentTemplate',
  //   '内容模版',
  // );

  // addPermissionRoute(
  //   '/udesk-center/contentTemplate/new',
  //   ['robot', 'login'],
  //   () => import("../routes/MicroApp/MicroApp"),
  //   'Udesk.ContentTemplate',
  //   '内容模版新增',
  // );

  // addPermissionRoute(
  //   '/udesk-center/contentTemplate/edit/:id',
  //   ['robot', 'login'],
  //   () => import("../routes/MicroApp/MicroApp"),
  //   'Udesk.ContentTemplate',
  //   '内容模版编辑',
  // );

  // addPermissionRoute(
  //   '/udesk-center/contentTemplate/clone/:id',
  //   ['robot', 'login'],
  //   () => import("../routes/MicroApp/MicroApp"),
  //   'Udesk.ContentTemplate',
  //   '内容模版克隆',
  // );
  //---
  addPermissionRoute(
    '/udesk-center/dispatch',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Udesk.Dispatch',
    '工单分派',
  );

  addPermissionRoute(
    '/udesk-center/dispatch/edit/eventTaskTypes/:id',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Udesk.Dispatch',
    '工单分派更新',
  );

  addPermissionRoute(
    '/udesk-center/jiraTransferConfig',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Udesk.JiraTransferConfig',
    'Jira流转配置',
  );

  //2023-12-13 功能下架暂不删除，删除时间待定
  // addPermissionRoute(
  //   '/rule-center/udeskRule-pool-list',
  //   ['robot', 'login'],
  //   () => import("../routes/MicroApp/MicroApp"),
  //   'Rule.UdeskRulePool',
  //   '工单规则池',
  // );

  addPermissionRoute(
    '/rule-center/executionObjectGroup-manager',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Rule.ExecutionObjectGroupManager',
    '执行对象组',
  );

  addPermissionRoute(
    '/rule-center/executionObjectGroup-manager/create',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Rule.ExecutionObjectGroupManager',
    '执行对象组新增',
  );

  addPermissionRoute(
    '/rule-center/executionObjectGroup-manager/edit/executionTargetGroups/:id',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Rule.ExecutionObjectGroupManager',
    '执行对象组编辑',
  );

  addPermissionRoute(
    '/rule-center/executionObjectGroup-manager/view/executionTargetGroups/:id',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Rule.ExecutionObjectGroupManager',
    '执行对象组查看',
  );

  addPermissionRoute(
    '/dataPlatform/coreSparePartBoard-Summary',
    [],
    () => import("../routes/DataPlatform"),
    'dataPlatform.coreSparePartBoard-Summary',
    '数据平台'
  );

  addPermissionRoute(
    '/dataPlatform/coreSparePartKanbanA',
    [],
    () => import("../routes/DataPlatform"),
    'dataPlatform.coreSparePartKanbanA',
    '数据平台'
  );

  addPermissionRoute(
    '/dataPlatform/coreSparePartKanbanB',
    [],
    () => import("../routes/DataPlatform"),
    'dataPlatform.coreSparePartKanbanB',
    '数据平台'
  );

  addPermissionRoute(
    '/dataPlatform/coreSparePartKanbanC',
    [],
    () => import("../routes/DataPlatform"),
    'dataPlatform.coreSparePartKanbanC',
    '数据平台'
  );

  addPermissionRoute(
    '/dataPlatform/coreSparePartKanbanX',
    [],
    () => import("../routes/DataPlatform"),
    'dataPlatform.coreSparePartKanbanX',
    '数据平台'
  );

  addPermissionRoute(
    '/dataPlatform/coreSparePartKanbanY',
    [],
    () => import("../routes/DataPlatform"),
    'dataPlatform.coreSparePartKanbanY',
    '数据平台'
  );

  addPermissionRoute(
    '/dataPlatform/coreSparePartKanbanZ',
    [],
    () => import("../routes/DataPlatform"),
    'dataPlatform.coreSparePartKanbanZ',
    '数据平台'
  );

  addPermissionRoute(
    '/dataPlatform/coreSparePartKanban-English',
    [],
    () => import("../routes/DataPlatform"),
    'dataPlatform.coreSparePartKanban-English',
    '数据平台'
  );

  addPermissionRoute(
    '/dataPlatform/faultTroubleShooting-Korean',
    [],
    () => import("../routes/DataPlatform"),
    'dataPlatform.faultTroubleShooting-Korean',
    '数据平台'
  );

  addPermissionRoute(
    '/dataPlatform/faultTroubleShooting-Japanese',
    [],
    () => import("../routes/DataPlatform"),
    'dataPlatform.faultTroubleShooting-Japanese',
    '数据平台'
  );

  addPermissionRoute(
    '/dataPlatform/faultTroubleShooting-English',
    [],
    () => import("../routes/DataPlatform"),
    'dataPlatform.faultTroubleShooting-English',
    '数据平台'
  );

  addPermissionRoute(
    '/dataPlatform/faultTroubleShooting-Chinese',
    [],
    () => import("../routes/DataPlatform"),
    'dataPlatform.faultTroubleShooting-Chinese',
    '数据平台'
  );

  addPermissionRoute(
    '/dataPlatform/robotUsageDataDashboard-English',
    [],
    () => import("../routes/DataPlatform"),
    'dataPlatform.robotUsageDataDashboard-English',
    '数据平台'
  );

  addPermissionRoute(
    '/dataPlatform/robotUsageDataDashboard-Chinese',
    [],
    () => import("../routes/DataPlatform"),
    'dataPlatform.robotUsageDataDashboard-Chinese',
    '数据平台'
  );


  // addPermissionRoute(
  //   '/rule-center/GenerateRules-manager',
  //   ['robot', 'login'],
  //   () => import("../routes/MicroApp/MicroApp"),
  //   'Rule.GenerateRulesManager',
  //   '生成规则',
  // );

  // addPermissionRoute(
  //   '/rule-center/GenerateRules-manager/create',
  //   ['robot', 'login'],
  //   () => import("../routes/MicroApp/MicroApp"),
  //   'Rule.GenerateRulesManager',
  //   '生成规则新增',
  // );

  // addPermissionRoute(
  //   '/rule-center/GenerateRules-manager/edit/:id',
  //   ['robot', 'login'],
  //   () => import("../routes/MicroApp/MicroApp"),
  //   'Rule.GenerateRulesManager',
  //   '生成规则编辑',
  // );

  // addPermissionRoute(
  //   '/rule-center/GenerateRules-manager/view/:id',
  //   ['robot', 'login'],
  //   () => import("../routes/MicroApp/MicroApp"),
  //   'Rule.GenerateRulesManager',
  //   '生成规则查看',
  // );

  addPermissionRoute(
    '/rule-center/incidentGroup-manager',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Rule.IncidentGroupManager',
    '事件组',
  );

  addPermissionRoute(
    '/rule-center/incidentGroup-manager/create',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Rule.IncidentGroupManager',
    '事件组新增',
  );

  addPermissionRoute(
    '/rule-center/incidentGroup-manager/edit/incidentGroups/:id',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Rule.IncidentGroupManager',
    '事件组编辑',
  );

  addPermissionRoute(
    '/rule-center/incidentGroup-manager/view/incidentGroups/:id',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Rule.IncidentGroupManager',
    '事件组查看',
  );

  addPermissionRoute(
    '/task-center/task-manager',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Task.TaskManager',
    '任务中心-任务管理',
  );

  addPermissionRoute(
    '/task-center/task-manager/create',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Task.TaskManager',
    '任务中心-任务管理新增',
  );

  addPermissionRoute(
    '/task-center/task-manager/edit/eventTaskTypes/:id',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Task.TaskManager',
    '任务中心-任务管理更新',
  );

  addPermissionRoute(
    '/task-center/rule-manager',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Task.RuleManager',
    '任务中心-规则管理',
  );

  addPermissionRoute(
    '/task-center/rule-manager/create',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Task.RuleManager',
    '任务中心-规则管理新增',
  );

  addPermissionRoute(
    '/task-center/rule-manager/edit/:id',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Task.RuleManager',
    '任务中心-规则管理更新',
  );

  addPermissionRoute(
    '/task-center/rule-manager/view/:id',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Task.RuleManager',
    '任务中心-规则管理查看',
  );

  addPermissionRoute(
    '/task-center/close-task-manager',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Task.CloseTaskManager',
    '任务中心-关闭任务管理',
  );

  addPermissionRoute(
    '/task-center/close-task-manager/create',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Task.CloseTaskManager',
    '任务中心-关闭任务管理新增',
  );

  addPermissionRoute(
    '/task-center/close-task-manager/edit/:id',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Task.CloseTaskManager',
    '任务中心-关闭任务管理更新',
  );

  addPermissionRoute(
    '/task-center/close-rule-manager',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Task.CloseRuleManager',
    '任务中心-关闭规则管理',
  );

  addPermissionRoute(
    '/task-center/close-rule-manager/create',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Task.CloseRuleManager',
    '任务中心-关闭规则管理新增',
  );

  addPermissionRoute(
    '/task-center/close-rule-manager/edit/:id',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Task.CloseRuleManager',
    '任务中心-关闭规则管理更新',
  );

  addPermissionRoute(
    '/task-center/close-rule-manager/view/:id',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Task.CloseRuleManager',
    '任务中心-关闭规则管理查看',
  );

  addPermissionRoute(
    '/task-center/general/close-rule',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Task.CloseRuleManager',
    '任务中心-关闭规则管理-通用兜底规则',
  );


  addPermissionRoute(
    '/task-center/customerGroup-config',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Task.CustomerGroupConfig',
    '任务中心-客户组配置',
  );

  addPermissionRoute(
    '/task-center/customerGroup-config/create',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Task.CustomerGroupConfig',
    '任务中心-客户组配置新增',
  );

  addPermissionRoute(
    '/task-center/customerGroup-config/edit/robotGroups/:id',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Task.CustomerGroupConfig',
    '任务中心-客户组配置更新',
  );

  addPermissionRoute(
    '/task-center/task-label-config',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Task.TaskLabelConfig',
    '任务中心-任务标签配置',
  );

  addPermissionRoute(
    '/task-center/task-label-config/create',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Task.TaskLabelConfig',
    '任务中心-任务标签配置新增',
  );

  addPermissionRoute(
    '/task-center/task-label-config/view/:id',
    ['robot', 'login'],
    () => import("../routes/MicroApp/MicroApp"),
    'Task.TaskLabelConfig',
    '任务中心-任务标签配置查看',
  );

  addPermissionRoute(
    '/equipmentManagement/robotProfile',
    [],
    () => import("../routes/MicroApp/MicroApp"),
    'ArchiveManagement.Robot',
    '机器人设备档案管理',
  );

  addPermissionRoute(
    "/equipmentManagement/eventAnalysis/:sn?",
    [],
    () => import("../routes/MicroApp/MicroApp"),
    'ArchiveManagement.EventAnalysis',
    '事件分析(工厂)',
  );

  addPermissionRoute(
    '/equipmentManagement/defaultConfig',
    [],
    () => import("../routes/MicroApp/MicroApp"),
    'ArchiveManagement.DefaultConfig',
    '机器人出厂默认配置信息',
  );

  addPermissionRoute(
    "/equipmentManagement/defaultConfig/detail/:action/:sn",
    [],
    () => import("../routes/MicroApp/MicroApp"),
    'ArchiveManagement.DefaultConfig',
    '机器人出厂默认配置信息',
  )

//2023-12-12新增内外部页面控制，路由注册新增identification字段，
// 值为 INTERNAL_EXTERNAL 时代表内外部都可访问页面，
// 值为 INTERNAL 时代表仅内部可见，
// 值为 EXTERNAL 时代表仅外部可见

  // 非权限路由
  const assertRouter = {
    '/': {
      component: dynamicWrapper(app, ['login'], () => import('../layouts/BasicLayout')),
    },
    '/exception/404': {
      component: dynamicWrapper(app, [], () => import('../routes/Exception/404')),
    },
    '/utils/dev': {
      component: dynamicWrapper(app, ['login'], () => import('../components/_utils/JSONView')),
    },
    '/components/MoveControl': {
      component: dynamicWrapper(app, ['login'], () => import('../components/MoveControl/MoveControl')),
    },
    '/robot/robot-status/sonar/:id': {
      component: dynamicWrapper(app, ['robot', 'login'], () => import('../routes/Robot/RobotStatus/RobotStatusSonar')),
    },
    '/robot/robot-status/protector/:id': {
      component: dynamicWrapper(app, ['robot', 'login'], () =>
        import('../routes/Robot/RobotStatus/RobotStatusProtector'),
      ),
    },
    '/exception/403': {
      component: dynamicWrapper(app, [], () => import('../routes/Exception/403')),
    },
    '/exception/500': {
      component: dynamicWrapper(app, [], () => import('../routes/Exception/500')),
    },
    '/setting/account': {
      name: i('个人中心'),
      component: dynamicWrapper(app, [], () => import('../routes/Setting/AccountSetting')),
    }
  };
  addPermissionRoute(
    '/robot/:robotExplain?',
    ['robot', 'login'],
    () => import('../routes/Robot/List/ListWrapper'),
    'Robot.Manager',
  );
  const allRouter = Object.assign(routerConfig, assertRouter);
  // Get name from ./menu.js or just set it in the router data.
  const menuData = getFlatMenuData(getMenuData());

  // Route configuration data
  // eg. {name,authority ...routerConfig }
  const routerData = {};
  // The route matches the menu
  Object.keys(allRouter).forEach((path) => {
    // Regular match item name
    // eg.  router /user/:id === /user/chen
    const pathRegexp = pathToRegexp(path);
    const menuKey = Object.keys(menuData).find((key) => pathRegexp.test(`${key}`));
    let menuItem = {};
    // If menuKey is not empty
    if (menuKey) {
      menuItem = menuData[menuKey];
    }
    let router = allRouter[path];
    // If you need to configure complex parameter routing,
    // https://github.com/ant-design/ant-design-pro-site/blob/master/docs/router-and-nav.md#%E5%B8%A6%E5%8F%82%E6%95%B0%E7%9A%84%E8%B7%AF%E7%94%B1%E8%8F%9C%E5%8D%95
    // eg . /list/:type/user/info/:id
    router = {
      ...router,
      name: router.name || menuItem.name,
      authority: router.authority || menuItem.authority,
      hideInBreadcrumb: router.hideInBreadcrumb || menuItem.hideInBreadcrumb,
    };
    routerData[path] = router;
  });
  return routerData;
};
